<template>
  <div>
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <b-row>
          <b-col
            lg="2"
            md="2"
            sm="2"
            class="my-1"
          >
            <b-button-group>

              <btnExportar
                modulo="Clientes"
                :items="items"
                :disabledExport="disabledExport"
              />

              <btnImportar
                v-if="$can('create', 'clientes')"
                modulo="Clientes"
              />
              <modalImportar
                v-if="$can('create', 'clientes')"
                modulo="Clientes"
              />

            </b-button-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            lg="2"
            md="3"
            sm="3"
            class="my-1"
          >

            <!-- BOTON MOSTRAR -->
            <btnMostrar
              :pageOptions.sync="pageOptions"
              :perPage.sync="perPage"
              :total.sync="items.length"
            />

          </b-col>
          <b-col
            lg="6"
            md="5"
            sm="5"
            class="my-1"
          >
            <!-- FILTRO -->
            <inputFiltro
              :filter.sync="filter"
            />

          </b-col>
          <b-col
            md="4"
            sm="4"
            class="my-1"
          >

            <!-- BOTON CREAR -->
            <btnCrear
              texto="Cliente"
              modulo="clientes"
              @processAdd="addCliente"
            />

          </b-col>
          <b-col
            cols="12"
          >
            <b-table
              striped
              small
              hover
              noCollapse
              class="mt-1"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              :busy="cargando"
              @filtered="onFiltered"
            >
              <!-- Cargando -->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <spinner />
                </div>
              </template>

              <!-- Header: Check -->
              <template #head(colCheck)="data">

                <b-form-checkbox
                  :id="data.label"
                  v-model="chkTodo"
                />

              </template>

              <!-- Column: Check -->
              <template #cell(colCheck)="data">

                <b-form-checkbox
                  :id="`chk-${data.item.id}`"
                  v-model="data.item.chkSelected"
                />

              </template>

              <!-- Column: created_at -->
              <template #cell(created_at)="data">
                {{ formatearFechaHora(data.item.created_at) }}
              </template>

              <!-- Column: Action -->
              <template #cell(acciones)="data">

                <colAccionesBtnes
                  modulo="clientes"
                  :data="data"
                  :estado="Boolean(data.item.estadoCount)"
                  :cantidad="Number(data.item.countSalidas)"
                  @processGoToClone="goToClone"
                  @processGoToUpdate="goToUpdate"
                  @processRemove="remove(data.item)"
                />

              </template>

            </b-table>
          </b-col>
          <b-col
            cols="12"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />

          </b-col>
        </b-row>

      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BButtonGroup, BFormCheckbox, BOverlay, BCard,
} from 'bootstrap-vue'
import { formatos } from '@/mixins/forms'
import { mapGetters, mapActions, mapMutations } from 'vuex'

const colAccionesBtnes = () => import('@/layouts/components/Recycled/List/colAccionesBtnes.vue')
const btnCrear = () => import('@/layouts/components/Recycled/List/btnCrear.vue')
const inputFiltro = () => import('@/layouts/components/Recycled/List/inputFiltro.vue')
const btnMostrar = () => import('@/layouts/components/Recycled/List/btnMostrar.vue')
const btnExportar = () => import('@/layouts/components/Recycled/List/btnExportar.vue')
const btnImportar = () => import('@/layouts/components/Recycled/List/btnImportar.vue')
const modalImportar = () => import('./components/modalImportar.vue')
const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BButtonGroup,
    BFormCheckbox,
    BOverlay,
    BCard,

    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    btnExportar,
    btnImportar,
    modalImportar,
    spinner,
  },
  mixins: [formatos],
  data() {
    return {
      spinner: false,
      cargando: true,
      // chk
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [3, 5, 10],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'colCheck',
          label: 'chkHeader',
          sortable: false,
          thStyle: {
            width: '0px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'created_at',
          label: 'Fecha Creación',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '160px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'rut',
          label: 'Rut',
          sortable: false,
          thStyle: {
            width: '110px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'razonSocial',
          label: 'Razón Social',
          sortable: true,
          thStyle: {
            width: '280px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'direccion',
          label: 'Dirección',
          sortable: false,
          thStyle: {
            width: '350px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'telefono',
          label: 'Teléfono',
          sortable: false,
          thStyle: {
            width: '80px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'correo',
          label: 'Correo',
          sortable: false,
          thStyle: {
            width: '80px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getClientes: 'clientes/getClientes' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    disabledExport() {
      return this.chkCount()
    },
  },
  watch: {
    getClientes(val) {
      this.totalRows = val.length
      this.items = this.getClientes
    },
    chkTodo() {
      this.chkAll()
    },
  },
  mounted() {
    this.fetchClientes().then(() => {
      this.cargando = false
    })
  },
  methods: {
    ...mapActions({
      fetchClientes: 'clientes/fetchClientes',
      updateClienteEstado: 'clientes/updateClienteEstado',
      removeClientes: 'clientes/removeClientes',
    }),
    ...mapMutations('clientes', ['setCliente']),
    addCliente() {
      this.$router.replace({
        name: 'clientes-create',
      })
    },
    updateEstado(cliente) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${cliente.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.updateClienteEstado(cliente).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Estado actualizado!',
              text: `Se ha actualizado el estado de "${cliente.nombre}"!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },
    goToClone(cliente) {
      this.setCliente(cliente)
      this.$router.push({
        name: 'clientes-clone',
        params: { id: cliente.id },
      })
    },
    goToUpdate(cliente) {
      this.setCliente(cliente)
      this.$router.push({
        name: 'clientes-update',
        params: { id: cliente.id },
      })
    },
    remove(cliente) {
      this.$swal({
        title: 'Eliminar cliente!',
        text: `Estás seguro que deseas eliminar el cliente "${cliente.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.removeClientes(cliente.id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminado con éxito!',
              text: `"${cliente.nombre}" ha sido eliminado!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
